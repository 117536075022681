.App {
  display: grid;
  grid-template-rows: 100vh;
  overflow: hidden;
}

.App-videobg {
  -o-object-fit: cover;
  display: block;
  grid-area: 1/1/-1/-1;
  height: 100vh;
  margin: 0 auto;
  object-fit: cover;
  width: 100vw;
  z-index: 1;
}

.App-content {
  -ms-flex-line-pack: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  align-content: center;
  display: grid;
  grid-area: 1/1/-1/-1;
  justify-content: center;
  text-align: center;
  z-index: 2;
  background-color: hsla(0, 0%, 100%, 0.2);
  background-image: -webkit-linear-gradient(
    170deg,
    hsla(0, 0%, 100%, 0.1) 65%,
    hsla(0, 0%, 100%, 0.15) 0
  );
}

.App-logo {
  height: auto;
  pointer-events: none;
  margin: 0 auto;
}

.App-h1 {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.4em;
  margin: 0.8em 0 0.5em;
}

p a {
  color: #3d4044;
}
