@import url(https://fonts.googleapis.com/css?family=Roboto);

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

:after,
:before {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  cursor: default;
  line-height: 1.15;
  word-break: break-word;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #3d4044;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
